import React from 'react';
import { useSetState } from 'react-use';
import { IoIosArrowDropdown } from 'react-icons/io';
import { format } from 'date-fns';
import { Facebook, Twitter } from 'react-social-sharing';


import BlockContent from 'molecules/BlockContent';
import Button from 'molecules/Button';
import CategoryLinks from 'organisms/CategoryLinks';
import Container from 'molecules/Container';
import Hero from 'organisms/Hero';
import HTML from 'molecules/HTML';
import Link from 'molecules/Link';
import Select from 'molecules/Select';

import './Post.scss';

import blogBanner from 'static/images/blog-banner.jpg';
import categories from 'data/globals/categories';
import recentPosts from 'data/globals/recent-posts';

// categories.sort( ( a, b ) => {
//   if( a.name > b.name ) {
//     return 1;
//   }
//   else {
//     return -1;
//   }
// } );
// const categoryOptions = [
//   <Select.Option value="select">Filter posts by category</Select.Option>
// ];
// for( let c of categories ) {
//   categoryOptions.push( <Select.Option value={ typeof c.slug === 'object' ? c.slug.current : c.slug }>{ c.name }</Select.Option> );
// }

const Post = ( { pageContext: post } ) => {

  /* ------ = Functions. = --------------------------------------------------------------------- */


  /* ------ = Lifecycle. = --------------------------------------------------------------------- */

  /* ------ = Output. = --------------------------------------------------------------------- */

  return(

    <article className="Post">

      <Hero
      size="sm"
      data={ {
        bg: {
          src: blogBanner
        }
      } }
      />


      <Container>
        <div className="row">

          <div className="col-md-8">

            { /* Header */ }
            <header className="Post__top">
              <h1 className="Post__top-title">{ post.title }</h1>

              <div className="Post__top-meta">
                <span className="date">
                  { format( new Date( post.datePublished ), 'MMMM d, yyyy' ) }
                </span>
                <span className="cats">
                  Categories: { !! post.categories && post.categories.map( c => (
                    <Link to={ `/blog?category=${ typeof c.slug === 'object' ? c.slug.current : c.slug }` }>
                      { c.name }
                    </Link>
                  ) ) }
                </span>
              </div>

            </header>

            { /* Content */ }
            <div className="Post__content">

              { !! post.content
                ? <BlockContent blocks={ post.content } />
                : <HTML>{ post.legacyContent }</HTML>
              } }

            </div>

            { /* Bottom */ }
            { typeof window !== 'undefined' &&

              <div className="Post__bottom">
                <div className="Post__bottom-share">
                  <h3 className="hding">Share</h3>
                  <Facebook link={ window.location.href } />
                  <Twitter link={ window.location.href } />
                </div>
              </div>

            }

          </div>

          <div className="col-md-3 offset-1 d-none d-md-block">

            <aside className="Post__side">
              <div className="Post__side-recent">
                <h2 className="Post__side-hding">Recent Posts</h2>
                { recentPosts.slice( 0, 3 ).map( post => (

                  <Link
                  key={ post._id }
                  className="Post__side-post"
                  to={ post.slug.current }
                  >
                    <div className="date">
                      { format( new Date( post.datePublished ), 'MMMM d, yyyy' ) }
                    </div>
                    <h3 className="title">{ post.title }</h3>
                  </Link>

                ) ) }
              </div>

              <CategoryLinks />

            </aside>

          </div>

        </div>
      </Container>


    </article>


  );
}
export default Post;
